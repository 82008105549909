<template>
  <div>
    <div id="pageRegistPassword" class="mainContents">
      <section class="titleArea">
        <h2>会員登録</h2>
        <!-- /.titleArea -->
      </section>

      <template v-if="!sent">
        <form @submit.prevent="register">
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>

          <div class="passwordArea">
            <div class="inner">
              <dl>
                <div>
                  <dt>メールアドレス</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="email"
                      :disabled="sending"
                      type="email"
                      placeholder="time@gmail.com"
                    >
                  </dd>
                </div>
              </dl>
              <div class="buttonArea">
                <p>
                  <button :disabled="sending" type="submit" name="button">
                    送信
                  </button>
                </p>
                <!-- /.buttonArea -->
              </div>
            </div>
            <!-- /.loginArea -->
          </div>
        </form>
      </template>
      <template v-else>
        <div class="passwordArea">
          <div class="inner">
            登録したメールアドレスに、本登録の案内メールをお送りしました。
            メールを確認し、登録手続きを完了してください。
          </div>
        </div>
      </template>
      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sent: false,
      sending: false,
      email: '',
      errors: null
    }
  },
  methods: {
    register () {
      this.sending = true
      this.$store
        .dispatch('send', {
          email: this.email
        })
        .then(() => {
          this.sent = true
          this.sending = false
        })
        .catch(err => {
          this.sent = false
          this.sending = false
          this.errors = err.response.data.errors
        })
    }
  }
}
</script>

<style scoped></style>
